$(document).ready(function() {

    let duration = 300;

        $(`.pagecover .close`).on('click', function() {

                var popup = $('.pagecover');

            popup.fadeOut(duration);
        });
});